import React from "react";
import FooterTwo from "../Components/Footer/FooterTwo";
import HeaderThree from "../Components/Header/HeaderThree";
import bannerBg from "../assets/img/CCMIbackground.jpeg";
import PageBanner from "../Components/PageBanner";
import CmmiDetails from "../Components/Services/CmmiDetails";

const ServiceDetails = () => {
  return (
    <>
      <HeaderThree />
      <PageBanner
        title="CMMI Consultancy and Appraisal Services."
        bannerBg={bannerBg}
        currentPage="/services/Cmmi"
        bgColor="#f5f5f5"
      />
      <CmmiDetails />
      <FooterTwo />
    </>
  );
};

export default ServiceDetails;
