import React from "react";
// import { Link as RouterLink } from "react-router-dom";

// import image1 from '../../assets/img/gallery/hybrid-cloud-concept.png';
// import image2 from '../../assets/img/gallery/data-pipe-cloud.png';
// import image3 from '../../assets/img/gallery/cost-opt.png';

// import QualityAssurancCarousel from "../../Components/Brands/QualityAssurancCarousel";

// import {
//   imagesFunctionalAndUiTesting,
//   imagesTestAutomation,
//   imagesProductionReady,
//   imagesBenchMarking
// } from "./qualityAssuranceImagesImports";

import { Link, Element } from "react-scroll";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Autoplay } from "swiper/modules";
import SwiperCore from "swiper";
SwiperCore.use([Autoplay]);

const Details = () => {
  return (
    <section className="service-details-wrapper section-padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4 mt-5 mt-md-0 col-12 order-2 order-md-1">
            <div className="service-details-sidebar sticky-sidebar">
              <div className="single-service-sidebar">
                <div className="sidebar-title">
                  <h3>CMMI</h3>
                </div>
                <ul>
                  <li>
                    <Link
                      activeClass="active"
                      to="What-is-cmmi"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      What is CMMI?
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClass="active"
                      to="cmmi-maturity-levels"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      CMMI Maturity Levels
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClass="active"
                      to="eCav-consulting-services"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      eCav Consulting Services
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClass="active"
                      to="types-cmmi-appraisals"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      Types of CMMI Appraisals
                    </Link>
                  </li>
                  <li>
                    <Link
                      activeClass="active"
                      to="commitment"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                    >
                      Our Commitment
                    </Link>
                  </li>
                </ul>
              </div>
              {/* <div className="single-service-sidebar site_info_widget">
                                <div className="sidebar-title">
                                    <RouterLink to="/contact">Contact Us</RouterLink>
                                </div>
                                <div className="contact-us">
                                    <div className="single-contact-info">
                                        <div className="icon">
                                            <i className="fal fa-phone" />
                                        </div>
                                        <div className="contact-info">
                                            <span>Phone Number</span>
                                            <p>+1 202 714 4236 </p>
                                        </div>
                                    </div>
                                    <div className="single-contact-info">
                                        <div className="icon">
                                            <i className="fal fa-envelope" />
                                        </div>
                                        <div className="contact-info">
                                            <span>Email Address</span>
                                            <p>info@ecavtechnologies.com</p>
                                        </div>
                                    </div>
                                    <div className="single-contact-info">
                                        <div className="icon">
                                            <i className="fal fa-map-marker-alt" />
                                        </div>
                                        <div className="contact-info">
                                            <span>Office Address</span>
                                            <p>
                                                23266 Southdown Loudoun Valley
                                                <br /> Ashburn VA 20148, USA
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
            </div>
          </div>
          <div className="col-lg-9 col-md-8 ps-lg-5 col-12 order-1 order-md-2">
            <div className="service-details-content-wrapper pl-0 pl-md-4">
              <Element name="What-is-cmmi" style={{ marginBottom: "50px" }}>
                <h2>What is CMMI? </h2>
                {/* <img src={image1} alt="" /> */}
                <p>
                  The Capability Maturity Model Integration (CMMI) is a globally
                  recognized framework of best practices designed to elevate
                  organizational performance across various domains, including
                  product development, service delivery, and acquisition. CMMI
                  Version 3.0, released in 2023, introduces streamlined
                  practices that emphasize agility, performance improvement, and
                  alignment with business objectives.
                </p>
                {/* <QualityAssurancCarousel
                  carouselData={imagesFunctionalAndUiTesting}
                /> */}
              </Element>
              <Element
                name="cmmi-maturity-levels"
                style={{ marginBottom: "50px" }}
              >
                <h2>CMMI Maturity Levels </h2>
                {/* <img src={image2} alt="" /> */}
                <p>
                  CMMI defines five maturity levels to assess and enhance
                  organizational processes:
                </p>
                <ul>
                  <li>
                    <strong>1. Initial:</strong> Processes are unpredictable and
                    reactive; success depends on individual effort.
                  </li>
                  <li>
                    <strong>2. Managed:</strong> Processes are planned,
                    documented, and executed at the project level, ensuring
                    consistency.
                  </li>
                  <li>
                    <strong>3. Defined:</strong> Processes are
                    well-characterized, standardized, and integrated across the
                    organization.
                  </li>
                  <li>
                    <strong>4. Quantitatively Managed:</strong> Processes are
                    measured and controlled using quantitative data and metrics.
                  </li>
                  <li>
                    <strong>5. Optimizing:</strong> The organization focuses on
                    continuous process improvement through innovation and
                    proactive identification of weaknesses.
                  </li>
                </ul>

                {/* <QualityAssurancCarousel carouselData={imagesTestAutomation} /> */}
              </Element>
              <Element
                name="eCav-consulting-services"
                style={{ marginBottom: "50px" }}
              >
                <h2>eCav Consulting Services </h2>
                <p>
                  At eCav, we deploy a team of seasoned consultants dedicated to
                  tailoring CMMI solutions that meet your organization&apos;s
                  unique needs. Our services include:
                </p>
                <ul>
                  <li>
                    <strong>Model Interpretation:</strong> We assist in
                    contextualizing the CMMI model to align with your business
                    environment, ensuring its effective implementation.
                  </li>
                  <li>
                    <strong>Benchmark Appraisal Preparation:</strong> Our
                    consultants facilitate readiness by providing model
                    orientation, conducting gap analyses, training
                    evidence-gathering teams, and reviewing documentation to
                    ensure a successful appraisal.
                  </li>
                  <li>
                    <strong>Process Improvement Infrastructure:</strong> We
                    support the development of a robust process improvement
                    infrastructure, including process group orientation,
                    goal-driven metrics programs, and organizational training
                    initiatives.
                  </li>
                  <li>
                    <strong>High-Maturity Process Development:</strong>{" "}
                    Leveraging our extensive experience with high-maturity
                    organizations, we guide you in achieving Levels 4 and 5
                    capabilities by clarifying roles and responsibilities in
                    process development and maintenance.
                  </li>
                  <li>
                    <strong>Process Documentation:</strong> Our experts perform
                    comprehensive gap analyses, review existing documentation,
                    and establish effective methods for process analysis and
                    development, ensuring alignment with CMMI best practices.
                  </li>
                  <li>
                    <strong>Independent Process Area Reviews:</strong> We
                    conduct reviews in areas such as Process Quality Assurance
                    (PQA), Configuration Management (CM), and Engineering
                    Process Group (EPG) to identify and address challenges in
                    your process improvement program.
                  </li>
                </ul>
                {/* <QualityAssurancCarousel carouselData={imagesProductionReady} /> */}
              </Element>
              <Element
                name="types-cmmi-appraisals"
                style={{ marginBottom: "50px" }}
              >
                <h2>Types of CMMI Appraisals </h2>
                <p>
                  Understanding the various CMMI appraisal types is crucial for
                  selecting the one that best aligns with your
                  organization&apos;s objectives:
                </p>
                <ul>
                  <li>
                    <strong>Benchmark Appraisal:</strong> A comprehensive
                    evaluation to identify opportunities for process improvement
                    and enhance overall business performance.
                  </li>
                  <li>
                    <strong>Sustainment Appraisal:</strong> A periodic
                    &quot;check-up&quot; following a Benchmark Appraisal to
                    ensure the organization maintains its maturity level.
                  </li>
                  <li>
                    <strong>Action Plan Reappraisal:</strong> An opportunity for
                    organizations that narrowly missed their targeted maturity
                    level in a previous appraisal to reassess and achieve their
                    goals.
                  </li>
                  <li>
                    <strong>Evaluation Appraisal:</strong> An informal, flexible
                    approach to help organizations prepare for an appraisal and
                    identify improvement opportunities.
                  </li>
                </ul>
                {/* <QualityAssurancCarousel carouselData={imagesBenchMarking} /> */}
              </Element>
              <Element name="commitment" style={{ marginBottom: "50px" }}>
                <h2>Our Commitment </h2>
                <p>
                  eCav is proud to have a team of highly qualified,
                  CMMI-certified Lead Appraisers equipped to conduct
                  comprehensive appraisals tailored to your organizational
                  goals. We provide detailed guidance and support throughout the
                  appraisal process, ensuring a seamless and effective
                  experience. Contact us to schedule a free consultation and
                  discover how eCav&apos;s innovative approach can maximize the
                  returns on your process improvement initiatives.
                </p>
                {/* <QualityAssurancCarousel carouselData={imagesBenchMarking} /> */}
              </Element>
              {/* <blockquote>
                &quot;Our focus is on building the future with our clients, not
                just building software.&quot;
              </blockquote> */}
            </div>
            {/* <div className="faq-content pl-0 pl-md-4">
              <h2>Common Questions for Our Services:</h2>
              <div className="faq-accordion">
                <div className="accordion no-border me-lg-5" id="accordion">
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq1"
                        aria-expanded="false"
                        aria-controls="faq1"
                      >
                        What is functional and UI testing, and why is it
                        important for my application?
                      </button>
                    </h4>
                    <div
                      id="faq1"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        Functional and UI testing is a process of testing an
                        application&apos;s functionality and user interface to
                        ensure that the application meets the desired
                        requirements and provides a seamless user experience. It
                        is important for your application because it helps
                        identify any potential bugs or issues that could
                        negatively impact your users&apos; experience, and
                        allows you to fix those issues before they become bigger
                        problems.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq2"
                        aria-expanded="true"
                        aria-controls="faq2"
                      >
                        What is test automation, and how can it help my
                        business?
                      </button>
                    </h4>
                    <div
                      id="faq2"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        Test automation is the process of automating the testing
                        of your application to improve efficiency, reduce
                        errors, and speed up the overall testing process. By
                        automating repetitive tasks, you can free up your team
                        to focus on more important tasks and ensure that your
                        application is tested thoroughly before it is released
                        to the public.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq3"
                        aria-expanded="false"
                        aria-controls="faq3"
                      >
                        What is chaos testing, and why is it important for my
                        application?
                      </button>
                    </h4>
                    <div
                      id="faq3"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        Chaos testing is the process of deliberately introducing
                        failures and other types of unexpected behavior into
                        your application to test its resiliency and ensure that
                        it can continue to function even in the face of
                        unexpected events. It is important for your application
                        because it helps identify potential weaknesses or
                        vulnerabilities before they can be exploited by
                        attackers or cause downtime for your users.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq4"
                        aria-expanded="false"
                        aria-controls="faq4"
                      >
                        What is User Acceptance Testing, and why is it
                        important?
                      </button>
                    </h4>
                    <div
                      id="faq4"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        User Acceptance Testing (UAT) is a type of testing where
                        end-users of the application test the software to ensure
                        that it meets their requirements and specifications. It
                        is important because it helps identify any issues or
                        discrepancies before the system is deployed to
                        production, ensuring a smooth and successful launch.{" "}
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq5"
                        aria-expanded="false"
                        aria-controls="faq5"
                      >
                        What is Load Testing, and why is it important?
                      </button>
                    </h4>
                    <div
                      id="faq5"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        Load Testing is a type of testing where the system is
                        subjected to a high volume of user requests to test how
                        it performs under stress and to identify any bottlenecks
                        or performance issues. It is important because it helps
                        ensure that the system can handle high traffic volumes
                        and provide a reliable and consistent user experience.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq6"
                        aria-expanded="false"
                        aria-controls="faq6"
                      >
                        What is Compliance Testing, and why is it necessary?
                      </button>
                    </h4>
                    <div
                      id="faq6"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        Compliance Testing is a type of testing where the system
                        is tested against industry standards and regulations to
                        ensure that it meets the required compliance
                        requirements. It is necessary to ensure that the system
                        is secure and protects user data, and to avoid legal or
                        regulatory issues.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq7"
                        aria-expanded="false"
                        aria-controls="faq7"
                      >
                        What is the importance of security testing in the
                        context of system deployment?
                      </button>
                    </h4>
                    <div
                      id="faq7"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        Security testing is crucial in the context of system
                        deployment to identify and address any security
                        vulnerabilities that may exist in the system. This helps
                        ensure that the system is secure and protects user data,
                        and to avoid any security breaches or attacks that may
                        harm the system or its users.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq8"
                        aria-expanded="false"
                        aria-controls="faq8"
                      >
                        What is eCav&apos;s approach to application testing?
                      </button>
                    </h4>
                    <div
                      id="faq8"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        eCav focuses on ensuring that applications run smoothly,
                        quickly, and provide an excellent user experience.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq9"
                        aria-expanded="false"
                        aria-controls="faq9"
                      >
                        What is browser-level performance testing, and why is it
                        crucial?
                      </button>
                    </h4>
                    <div
                      id="faq9"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        Browser-level performance testing is a critical aspect
                        of the testing process to make sure that the front-end
                        application runs faster and offers a better user
                        experience under stress and load.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq10"
                        aria-expanded="false"
                        aria-controls="faq10"
                      >
                        How does eCav&apos;s team test systems for scalability
                        and performance?
                      </button>
                    </h4>
                    <div
                      id="faq10"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        eCav&apos;s team tests systems for scalability,
                        concurrent users, traffic management, and capacity
                        planning. Performance benchmarking tools like jMeter are
                        utilized to analyze end-to-end user flow APIs to ensure
                        high performance and scalability across multiple
                        environments.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq11"
                        aria-expanded="false"
                        aria-controls="faq11"
                      >
                        What is the ultimate goal of optimizing application
                        performance, scalability, and traffic management?
                      </button>
                    </h4>
                    <div
                      id="faq11"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        The aim is to deliver the best user experience by
                        optimizing application performance, ensuring
                        scalability, and providing traffic management that works
                        for all scenarios.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq12"
                        aria-expanded="false"
                        aria-controls="faq12"
                      >
                        Do you integrate data pipelines into CI/CD systems?
                      </button>
                    </h4>
                    <div
                      id="faq12"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        Yes, our team is adept at integrating data pipelines
                        into CI/CD systems, promoting a swift and low-touch
                        change process for an area that has traditionally been
                        slow and labor-intensive. This enables faster data
                        processing and analysis, which in turn can lead to more
                        informed business decisions.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq12"
                        aria-expanded="false"
                        aria-controls="faq12"
                      >
                        What are Monitoring and Logging Services and how can
                        they help my business?
                      </button>
                    </h4>
                    <div
                      id="faq12"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        Monitoring and Logging Services are systems that enable
                        you to track the performance and health of your
                        applications in real-time. At eCav, we design and
                        implement comprehensive and user-friendly monitoring and
                        logging infrastructures that enhance incident response
                        efficiency. This ensures that your operational teams are
                        informed about your system&apos;s status, enabling rapid
                        root-cause analysis and the implementation of corrective
                        measures to ensure SLA compliance.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq13"
                        aria-expanded="false"
                        aria-controls="faq13"
                      >
                        What are Monitoring and Logging Services and how can
                        they help my business?
                      </button>
                    </h4>
                    <div
                      id="faq13"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        Monitoring and Logging Services are systems that enable
                        you to track the performance and health of your
                        applications in real-time. At eCav, we design and
                        implement comprehensive and user-friendly monitoring and
                        logging infrastructures that enhance incident response
                        efficiency. This ensures that your operational teams are
                        informed about your system&apos;s status, enabling rapid
                        root-cause analysis and the implementation of corrective
                        measures to ensure SLA compliance.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq14"
                        aria-expanded="false"
                        aria-controls="faq14"
                      >
                        How do you establish centralized monitoring and logging
                        systems?
                      </button>
                    </h4>
                    <div
                      id="faq14"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        Our skilled engineers have experience in establishing
                        centralized monitoring and logging systems for small,
                        medium, and enterprise-scale applications. We work
                        closely with our clients to understand their specific
                        needs and develop customized solutions that meet their
                        requirements.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq15"
                        aria-expanded="false"
                        aria-controls="faq15"
                      >
                        What kind of dashboarding and alerting capabilities do
                        you configure?
                      </button>
                    </h4>
                    <div
                      id="faq15"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        We can swiftly configure dashboarding and alerting
                        capabilities that keep your operational teams informed
                        about your system&apos;s status. This includes
                        customized dashboards that provide real-time performance
                        metrics, as well as alerting capabilities that notify
                        your team of potential issues before they escalate.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq16"
                        aria-expanded="false"
                        aria-controls="faq16"
                      >
                        How long does it take to set up a Monitoring and Logging
                        infrastructure?
                      </button>
                    </h4>
                    <div
                      id="faq16"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        The time it takes to set up a Monitoring and Logging
                        infrastructure depends on the complexity of your
                        application and the scope of the project. Our team will
                        work closely with you to develop a customized solution
                        that meets your needs and fits your timeline.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq17"
                        aria-expanded="false"
                        aria-controls="faq17"
                      >
                        What is Cost Optimization and how can it help my
                        business?
                      </button>
                    </h4>
                    <div
                      id="faq17"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        Cost Optimization is the process of identifying and
                        reducing excess costs in cloud infrastructure, which can
                        adversely impact profitability. At eCav, we utilize the
                        best practices of Well-Architected Frameworks (WAFs) to
                        optimize infrastructure resources. Our optimization
                        strategies include usage-adaptive resource utilization,
                        auditing existing resource inventories, and setting up
                        billing thresholds and automated alerts to ensure you
                        stay in control of your cloud expenses.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq18"
                        aria-expanded="false"
                        aria-controls="faq18"
                      >
                        How do you ensure that our infrastructure resources are
                        being utilized efficiently?
                      </button>
                    </h4>
                    <div
                      id="faq18"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        Our certified engineers use best practices to ensure
                        that infrastructure resources are being utilized
                        efficiently. We employ usage-adaptive resource
                        utilization, which allows us to adjust resource
                        allocation based on actual usage patterns. This enables
                        us to identify excess resources and reduce costs by
                        rightsizing infrastructure.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq19"
                        aria-expanded="false"
                        aria-controls="faq19"
                      >
                        How do you ensure that our infrastructure resources are
                        being utilized efficiently?
                      </button>
                    </h4>
                    <div
                      id="faq19"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        Our certified engineers use best practices to ensure
                        that infrastructure resources are being utilized
                        efficiently. We employ usage-adaptive resource
                        utilization, which allows us to adjust resource
                        allocation based on actual usage patterns. This enables
                        us to identify excess resources and reduce costs by
                        rightsizing infrastructure.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq20"
                        aria-expanded="false"
                        aria-controls="faq20"
                      >
                        How do you set up billing thresholds and automated
                        alerts to ensure we stay in control of our cloud
                        expenses?
                      </button>
                    </h4>
                    <div
                      id="faq20"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        We set up billing thresholds and automated alerts to
                        ensure that you stay in control of your cloud expenses.
                        This includes setting up billing alerts when costs
                        exceed a certain threshold, as well as automating the
                        shutdown of idle resources to avoid unnecessary charges.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq21"
                        aria-expanded="false"
                        aria-controls="faq21"
                      >
                        How long does it take to see the benefits of cost
                        optimization?
                      </button>
                    </h4>
                    <div
                      id="faq21"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        The time it takes to see the benefits of cost
                        optimization depends on the complexity of your cloud
                        infrastructure and the scope of the project. However,
                        our optimization strategies are designed to deliver
                        measurable cost savings in a relatively short amount of
                        time.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq22"
                        aria-expanded="false"
                        aria-controls="faq22"
                      >
                        How long does it take to see the benefits of cost
                        optimization?
                      </button>
                    </h4>
                    <div
                      id="faq22"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        The time it takes to see the benefits of cost
                        optimization depends on the complexity of your cloud
                        infrastructure and the scope of the project. However,
                        our optimization strategies are designed to deliver
                        measurable cost savings in a relatively short amount of
                        time.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq23"
                        aria-expanded="false"
                        aria-controls="faq23"
                      >
                        What is Security by eCav and how can it help my
                        business?
                      </button>
                    </h4>
                    <div
                      id="faq23"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        Security by eCav is a suite of security services
                        designed to help organizations secure their cloud
                        environments. As organizations adopt a
                        &quot;cloud-first&quot; strategy to achieve
                        transformational agility and scalability in the public
                        cloud, security concerns can arise with each new
                        instance. eCav&apos;s cloud engineers are skilled in
                        conducting comprehensive security analyses of your
                        infrastructure and implementing robust security
                        measures. This ensures minimized security risks while
                        maintaining data privacy and integrity, keeping your
                        organization&apos;s cloud environment secure and
                        protected.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq24"
                        aria-expanded="false"
                        aria-controls="faq24"
                      >
                        What kind of security measures do you implement?
                      </button>
                    </h4>
                    <div
                      id="faq24"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        We implement a variety of security measures to ensure
                        the security of your cloud environment. This includes
                        identity and access management, encryption, network
                        security, and application security. We work closely with
                        our clients to develop customized security solutions
                        that meet their specific needs.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq25"
                        aria-expanded="false"
                        aria-controls="faq25"
                      >
                        How do you conduct a security analysis of our
                        infrastructure?
                      </button>
                    </h4>
                    <div
                      id="faq25"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        Our skilled engineers conduct a comprehensive security
                        analysis of your infrastructure, including identifying
                        potential vulnerabilities, assessing risk, and
                        implementing security measures. We use industry-standard
                        tools and methodologies to ensure the highest level of
                        security for your cloud environment.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq26"
                        aria-expanded="false"
                        aria-controls="faq26"
                      >
                        What steps do you take to maintain data privacy and
                        integrity?
                      </button>
                    </h4>
                    <div
                      id="faq26"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        We take data privacy and integrity very seriously and
                        follow best practices to ensure the confidentiality,
                        integrity, and availability of our clients&apos; data.
                        We implement security measures such as encryption,
                        access controls, and regular backups to ensure the
                        safety of your data.
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h4 className="accordion-header">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#faq27"
                        aria-expanded="false"
                        aria-controls="faq27"
                      >
                        How long does it take to implement security measures?
                      </button>
                    </h4>
                    <div
                      id="faq27"
                      className="accordion-collapse collapse"
                      data-bs-parent="#accordion"
                    >
                      <div className="accordion-body">
                        The time it takes to implement security measures depends
                        on the complexity of your infrastructure and the scope
                        of the project. However, our skilled engineers work
                        efficiently to ensure that security measures are
                        implemented as quickly as possible without compromising
                        the quality of the solution.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Details;
