import { v4 as uuidv4 } from "uuid";
import img1 from "../../assets/img/home3/s1-cloud.png";
import img2 from "../../assets/img/home3/s2-webapp.png";
// import img3 from '../../assets/img/home3/s3-network.png';
import img4 from "../../assets/img/home3/s4-design.png";
import img5 from "../../assets/img/home3/s5-qa.png";
import img6 from "../../assets/img/home3/s6-ai.png";
import img7 from "../../assets/img/home3/block.png";
import img8 from "../../assets/img/home3/cyber.png";

const servicesThreeData = [
  {
    id: uuidv4(),
    img: img1,
    title: "Cloud & DevOps",
    desc: "Cutting-edge cloud infrastructure, development and deployment.",
    pageName: "services/CloudAndDevops"
  },
  {
    id: uuidv4(),
    img: img2,
    title: "Software Development",
    desc: "Highly interactive and scalable web application development.",
    pageName: "services/SoftwareDevelopment"
  },
  {
    id: uuidv4(),
    img: img6,
    title: "CMMI",
    desc: "Achieve Excellence with CMMI: Get Certified and Elevate Your Business with eCav's Expertise.",
    pageName: "services/Cmmi"
  },
 {
    id: uuidv4(),
    img: img6,
    title: "Gen AI",
    desc: "Analytics and predictive analysis through AI learning.",
    pageName: "services/AIAndAnalytics"
  },
  {
    id: uuidv4(),
    img: img5,
    title: "Quality Assurance",
    desc: "Effective quality assurance services with test automation.",
    pageName: "services/QualityAssurance"
  },
  {
    id: uuidv4(),
    img: img8,
    title: "Cyber Security",
    desc: "Secure web apps with strong auth, encryption, and regular updates.",
    pageName: "services/CyberSecurity"
  },
  {
    id: uuidv4(),
    img: img7,
    title: "Block Chain",
    desc: "Blockchain security, use cryptography, decentralized consensus, and audits.",
    pageName: "services/BlockChain"
  },
  {
    id: uuidv4(),
    img: img4,
    title: "UI/UX",
    desc: "Intuitive product design with a simplified user experience.",
    pageName: "services/DesignUX"
  }
];

export default servicesThreeData;
