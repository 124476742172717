import pdfFile from "../../assets/docs/Capability Statement.pdf";

const CapabilityStatement = () => {
  return (
    <div className="hero-about-container">
      <div className="col-xl-6 col-lg-8 mb-4 offset-xl-3 offset-lg-2 col-12 text-center">
        {/* <a
          href={pdfFile}
          // download="Capability_Statement.pdf"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: "none",
            color: "inherit",
            cursor: "pointer"
          }}
        >
          <div className="statement">
            <h3>Capability Statement</h3>
          </div>
        </a> */}
        <a
          href={pdfFile}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: "none",
            color: "inherit",
            cursor: "pointer"
          }}
        >
          <div
            className="statement"
            style={{
              // padding: "10px",
              // border: "1px solid #ccc",
              borderRadius: "5px",
              textAlign: "center",
              backgroundColor: "#fff",
              transition: "transform 0.3s"
            }}
            onMouseEnter={(e) => {
              // e.target.style.backgroundColor = "#e0e0e0";
              e.target.style.transform = "scale(1.02)";
            }}
            onMouseLeave={(e) => {
              // e.target.style.backgroundColor = "#f9f9f9";
              e.target.style.transform = "scale(1)";
            }}
          >
            <h3 style={{ margin: 0 }}>Capability Statement</h3>
          </div>
        </a>
      </div>
      <div className="row">
        <div className="col-xl-4 col-md-6">
          <div className="single-services-box-three sb1">
            <h3 className="text-center">Certifications</h3>
            <div className="content" style={{ marginTop: 20 }}>
              {/* <p>Small Business Designation </p> */}
              {/* <p>Service-Disabled Vet. (SDVOB) </p> */}
              {/* <p>Vet. Owned Small Bus. (VOSB) </p> */}
              <p>Small Business </p>
              <p>Minority Owned </p>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-6">
          <div className="single-services-box-three sb2">
            <h3 className="text-center">NAICS</h3>
            <div
              className="content justify-content-center"
              style={{ marginTop: 20 }}
            >
              {/* <p>541511</p>
              <p>541512</p>
              <p>541519</p>
              <p>518210</p>
              <p>541715</p> */}
              <p>541511, 541512, 541519, 518210, 541715</p>
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-md-6">
          <div className="single-services-box-three sb3">
            <h3 className="text-center">Federal Govt. Compliance</h3>
            <div className="content" style={{ marginTop: 20 }}>
              <p>DUNS Num: 106768259C</p>
              <p>CAGE Code: 94CL1</p>
              <p>SAM ID: X53BBR3GYNN8</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CapabilityStatement;
