import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const MobileMenu = ({ mobileMenu, handleMobileMenu }) => {
  return (
    <div className={mobileMenu ? 'mobile-nav' : 'mobile-nav show'}>
      <button onClick={handleMobileMenu} type="button" className="close-nav">
        <i className="fal fa-times-circle"></i>
      </button>
      <nav className="sidebar-nav">
        <ul className="metismenu" id="mobile-menu">
          <li>
            <a className="has-arrow" href="#">
              <Link to="/">Home</Link>
            </a>
          </li>
          <li>
            <Link to="/about">about us</Link>
          </li>
          <li>
            <a className="has-arrow" href="#">
              Services
            </a>
            <ul className="sub-menu">
              <li>
                <Link to="/services">Overview</Link>
              </li>
              <li>
                <Link to="/services/CloudAndDevops">Cloud &amp; Devops</Link>
              </li>
              <li>
                <Link to="/services/SoftwareDevelopment">Software Development</Link>
              </li>
              <li>
                <Link to="/services/AIAndAnalytics">Gen AI</Link>
              </li>
              <li>
                <Link to="/services/DesignUX">Design, UX</Link>
              </li>
              <li>
                <Link to="/services/QualityAssurance">Quality Assurance</Link>
              </li>
              <li>
                <Link to="/services/CyberSecurity">Cyber Security</Link>
              </li>
              <li>
                <Link to="/services/BlockChain">Bloack Chain</Link>
              </li>
              <li>
                <Link to="/services/EmbeddedSystems">Embedded System of Networking</Link>
              </li>
            </ul>
          </li>
          <li>
            <a className="has-arrow" href="#">
              Pages
            </a>
            <ul className="sub-menu">
              <li>
                <Link to="/faq">faq</Link>
              </li>
              {/* <li>
                <Link to="/team">Team</Link>
              </li> */}
            </ul>
          </li>
          <li>
            <Link to="/goverment">Goverment</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </nav>

      <div className="action-bar">
        <a href="mailto:info@ecavtechnologies.com">
          <i className="fal fa-envelope"></i>info@ecavtechnologies.com
        </a>
        <a href="tel:+1 202 714 4236">
          <i className="fal fa-phone"></i>+1 202-714-4236
        </a>
        <Link to="/contact" className="d-btn theme-btn">
          Contact Us
        </Link>
      </div>
    </div>
  );
};

MobileMenu.propTypes = {
  mobileMenu: PropTypes.bool.isRequired,
  handleMobileMenu: PropTypes.func.isRequired
};

export default MobileMenu;